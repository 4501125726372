export class DevelopDto {
  public _id: string;
  public Code: string;
  public Name: string;
  public Description: string;
  public MinDownPaymentOnly: number;
  public MinSumDownPayment_Quotes: number;
  public MinSumDownPayment_QuotesToApplyDiscount: number;
  public Active: boolean;

  constructor(
    _id: string,
    Code: string,
    Name: string,
    Description: string,
    MinDownPaymentOnly: number,
    MinSumDownPayment_Quotes: number,
    MinSumDownPayment_QuotesToApplyDiscount: number,
    Active: boolean) {
        this._id = _id;
        this.Code = Code;
        this.Name = Name;
        this.Description = Description;
        this.MinDownPaymentOnly                      = MinDownPaymentOnly;
        this.MinSumDownPayment_Quotes                = MinSumDownPayment_Quotes;
        this.MinSumDownPayment_QuotesToApplyDiscount = MinSumDownPayment_QuotesToApplyDiscount;
        this.Active = Active;
  }
}
