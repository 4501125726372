import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root',
})

export class PaymentFormsService {

  constructor(private _baseService: BaseService ) {}

  public AddPaymentForm( paymentform ) {
    return this._baseService.Post('addpaymentform', paymentform);
  }

  public UpdatePaymentForm( paymentform_id, paymentform ) {
    return this._baseService.Put('updatepaymentform/' + paymentform_id, paymentform);
  }

  public GetPaymentForm( id ) {
    return this._baseService.Get('getpaymentform/' + id);
  }

  public DeletePaymentForm( id ) {
    return this._baseService.Delete('deletepaymentform/' + id);
  }


}

