export class PaymentFormDto {
  public _id: string;
  public PaymentFormType: string;
  public DepartmentCode: string;
  public SaleValue: number;
  public Fraction_Percent: number;
  public Fraction_Value: number;
  public TotalMeters: number;
  public ValuePerMeter: number;
  public DepositPercent: number;
  public Deposit: number;
  public Discount: number;
  public DiscountPLS: number;
  public FinalValue: number;
  public Balance: number;
  public Percentages4Quotes: number;
  public QuotaValue: number;
  public Quotes: number;
  public FinalDelivery: number;
  public ReservationDate: Date;
  public TotalQuotes: number;
  public TotalDiscountAmount: number;

  constructor(
      _id: string,
      PaymentFormType: string,
      DepartmentCode: string,
      SaleValue: number,
      Fraction_Percent: number,
      Fraction_Value: number,
      TotalMeters: number,
      ValuePerMeter: number,
      DepositPercent: number,
      Deposit: number,
      Discount: number,
      DiscountPLS: number,
      FinalValue: number,
      Balance: number,
      Percentages4Quotes: number,
      QuotaValue: number,
      Quotes: number,
      FinalDelivery: number,
      ReservationDate: Date,
      TotalQuotes,
      TotalDiscountAmount) {

    this._id = _id;
    this.PaymentFormType     = PaymentFormType;
    this.DepartmentCode      = DepartmentCode;
    this.SaleValue           = SaleValue;
    this.Fraction_Percent    = Fraction_Percent;
    this.Fraction_Value      = Fraction_Value;
    this.TotalMeters         = TotalMeters;
    this.ValuePerMeter       = ValuePerMeter;
    this.DepositPercent      = DepositPercent;
    this.Deposit             = Deposit;
    this.Discount            = Discount;
    this.DiscountPLS         = DiscountPLS;
    this.FinalValue          = FinalValue;
    this.Percentages4Quotes  = Percentages4Quotes;
    this.QuotaValue          = QuotaValue;
    this.Quotes              = Quotes;
    this.Balance             = Balance;
    this.FinalDelivery       = FinalDelivery;
    this.ReservationDate     = ReservationDate;
    this.TotalQuotes         = TotalQuotes;
    this.TotalDiscountAmount = TotalDiscountAmount;
  }
}
