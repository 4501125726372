import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root',
})

export class ReserveDepartmentsService {

  constructor(private _baseService: BaseService ) {}

  public ReserveFractionDepartment( params ) {
    return this._baseService.Post('reserve-fraction-department', params);
  }

  public GetReserveDepartments(developId) {
    return this._baseService.Get('get-reserve-departments/' + developId);
  }

  public ReleaseFractionDepartment( params ) {
    return this._baseService.Post('release-fraction-department', params);
  }

  public SellReservation( params ) {
    return this._baseService.Post('sell-reservation', params);
  }

  public GetCustomersReserves(customerId) {
    return this._baseService.Get('get-customers-reserves/' + customerId);
  }

}
